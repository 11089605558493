import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom'
import { getPatient } from '../../../patient/Patient';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { CircularProgress } from "@mui/material";
import PatientSelectQuery from '../../../patient/PatientSelectQuery';
import { PatientQueryToggle } from '../../../patient/PatientWideInfo';
import VSError from '../../../../components/vsError/VSError';

import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from '../../../../constants';

import './VirtualAssistantClinicalHistorySummary.scss';
import { dayjs, saveOnTracker } from '../../../../utils';
const VirtualAssistantClinicalHistorySummary = props => {

    const {
        id_animal
    } = useParams()

    const { state } = useLocation()

    const {
        user,
        clinic,
        history,
        permissions,
        setDrawerError
    } = props

    const isAnimalSelected = id_animal != "selecionar"

    const [ loading, setLoading ] = useState(false)
    const [ loadingSummary, setLoadingSummary ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ patient, setPatient ] = useState(null)
    const [ patientSummary, setPatientSummary ] = useState("")

    const [ trackerSource, setTrackerSource ] = useState(state?.trackerSource ? state?.trackerSource : "")
    const [ trackerSourceDetail, setTrackerSourceDetail ] = useState("")

    const getPatientOrError = () => {
        if (permissions?.check(ACL.PATIENT, ACL.Rule.VIEW)) {
            setLoading(true)
            setError(null)
    
            if (isAnimalSelected) {
                getPatient(id_animal, clinic.object)
                .then(_patient => {
                    setPatient(_patient)
                    setLoading(false)
                    setLoadingSummary(true)
                    return Parse.Cloud.run("aiSummarizedPatientHistory", {
                        clinicId: clinic.id || clinic.objectId,
                        patientId: _patient.id || _patient.objectId,
                    }).then(({ assistant, instructions, user, ...rest }) => {
                        setPatientSummary(assistant)
                        setLoadingSummary(false)
                        saveOnTracker("Concluiu", "Assistente", "Gerar Resumo", null, trackerSource, null, trackerSourceDetail, JSON.stringify(Object.assign({
                            patientId: _patient.id || _patient.objectId
                        }, rest)))
                    })
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error?.message,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getPatientOrError"
                        })
                    })
                })
            } else {
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        if (patient && !permissions?.check(ACL.PATIENT, ACL.Rule.EDIT, patient)) { // TODO: view or edit permission?
            permissions?.error()
        }
    }, [ patient ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            // saveOnTracker("Iniciou", "Animal", id !== "novo" ? id : "")
            if (isAnimalSelected) {
                getPatientOrError()
            }

            // getSpecies().then(_species => setSpecies(_species))
            // getGenders().then(_genders => setGenders(_genders))
            // getPelages().then(_pelages => setPelages(_pelages))
            // getTemperaments().then(_temperaments => setTemperaments(_temperaments))
        }
    }, [ clinic, id_animal ])

    useEffect(() => {
        if(state?.trackerSource){
            setTrackerSource(state?.trackerSource)
        }
    }, [ state ])

    const getHistoryCountTextForPatient = history => history && (
        <> e {history?.length > 0 ? <>possui <b>{history?.length} registros</b></> : <b>não possui registros</b>} em seu histórico</>
    )

    const formatSummary = summary => {
        if(!summary){
            return "Animal sem histórico disponível para resumo."
        }
        // 
        const _summary = summary.split("\n\n").join("\n").split("\nAtendimento").join("\n\nAtendimento");
        return _summary.split("\n\n").map((summartPart, summartPartIndex) => summartPart ? (
            <div key={summartPartIndex} className="summary-part">
                {summartPart.split("\n").map((summaryLine, summaryLineIndex) => summaryLine ? (
                    <div key={`${summartPartIndex} ${summaryLineIndex}`} className="summary-line">{summaryLine.split(": ").map((summaryLinePart, summaryLinePartIndex, summaryLineList) => summaryLinePart ? (
                        summaryLineIndex > 0 && summaryLinePartIndex == 0 ? <b>{summaryLinePart}: </b> : (
                            summaryLineList.length - 1 == summaryLinePartIndex ? summaryLinePart : <>{summaryLinePart}: </>
                        )
                    ) : <></>)}</div>
                ) : <></>)}
            </div>
        ) : <></>)
    }

    return (
        <div id="virtual-assistant-clinical-history-summary">
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                !isAnimalSelected ? (
                    <PatientSelectQuery
                        getNextRoute={(_patient, source) => {
                            history.replace(`/assistente/resumo-historico-clinico/animal/${_patient?.id}?selecionado`)
                            
                            const _trackerSource = "Busca por Animal ou Tutor"
                            const _trackerSourceDetail = source == "BoxOwner" ? "Tutor" : source == "BoxPatient" ? "Animal" : ""
                            setTrackerSource(_trackerSource)
                            setTrackerSourceDetail(_trackerSourceDetail)
                            saveOnTracker("Clicou", "Assistente", "Gerar Resumo", null, _trackerSource, null, _trackerSourceDetail, { patientId: _patient?.id })
                        }}
                        selectPatientLabel="GERAR RESUMO"
                        appendContentToBoxPatient={(_patient, _history) => 
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="va-clinical-history-summary-box-patient-append">
                                        Este animal é paciente desde {dayjs(_patient?.get("createdAt")).format("YYYY")}{getHistoryCountTextForPatient(_history)}.
                                    </div>
                                </div>
                            </div>
                        }
                        includePatientHistory
                        boxPatientCallOnSelectByBoxClick
                        history={history}
                    />
                ) : (
                    <>
                        <PatientQueryToggle
                            patient={patient}
                            onClick={() => history.replace(`/assistente/resumo-historico-clinico/animal/selecionar`)}
                        />
                        <div className="row align-items-end mb-3">
                            <div className="col">
                                <Tabs
                                    className="vstabs"
                                    value={"summary"}
                                    // onChange={(_, selected) => null}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile
                                >
                                    <Tab 
                                        value="summary"
                                        label="Resumo do Histórico Clínico do Animal:"
                                    />
                                </Tabs>
                            </div>
                        </div>
                        {loadingSummary ? (
                            <div className="row data-loading pt-5">
                                <div className="col-12">
                                    <CircularProgress />
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="loading-title col-12">
                                            Gerando resumo do histórico clínico
                                        </div>
                                        <div className="loading-subtitle col-12">
                                            Por favor, aguarde alguns segundos enquanto analisamos todos os registros.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="row summary-container">
                                    <div className="col">
                                        {formatSummary(patientSummary)}
                                    </div>
                                </div>
                                <div className="vsdrawer-footer">
                                    <p><b>Encontrou algum erro ou sugestão de melhoria?</b> Nos ajude a aprimorar as nossas respostas. <a href="https://petlove-vetsmart-vetus.typeform.com/to/qLaJ9vR9" target="_blank">Enviar feedback</a></p>
                                </div>
                            </>
                        )}
                    </>
                )
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(VirtualAssistantClinicalHistorySummary)