import React from 'react'

import { saveOnTracker } from '../../utils';

const HomeLimit = (props) => {

    const {
        history
    } = props

    return (
        <div className="vsbox card-pro-advertising">
            <div className="vsbox-body">
                <div className="row g-3">
                    <div className="col-12">
                        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_68_11711" style={{ maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="74" height="74">
                                <rect width="74" height="74" rx="16" fill="#D9F8F6"/>
                            </mask>
                            <g mask="url(#mask0_68_11711)">
                                <rect width="92" height="92" transform="matrix(-1 0 0 1 84.0762 -7.96582)" fill="#D9F8F6"/>
                                <path d="M77.7005 17.4556H14.836C14.6115 17.4556 14.6115 17.6585 14.6115 17.6585V22.3256C14.6115 22.5285 14.836 22.5285 14.836 22.5285H77.7005C78.1496 22.5285 78.3741 22.3256 78.3741 22.3256V17.6585C78.3741 17.4556 78.1496 17.4556 77.7005 17.4556Z" fill="#00CFC2"/>
                                <path d="M59.5223 22.1227C59.5223 21.5139 59.7468 21.1081 59.7468 20.4994C59.7468 14.0061 53.9094 8.52734 46.5004 8.52734C39.0913 8.52734 33.2539 13.8031 33.2539 20.4994C33.2539 21.1081 33.2539 21.5139 33.4784 22.1227H17.0887V64.532H75.0139V22.1227H59.5223Z" fill="#F1F1F1"/>
                                <path d="M79.0487 69.6051H77.2526C77.9261 69.6051 78.3751 69.1992 78.3751 68.5905V65.1409C78.3751 64.5322 77.9261 64.1264 77.2526 64.1264H62.21V39.3707C62.21 37.9503 61.0874 36.9357 59.5158 36.9357H33.4719C31.9003 36.9357 30.7777 37.9503 30.7777 39.3707V64.1264H15.7351C15.0616 64.1264 14.6125 64.5322 14.6125 65.1409V68.5905C14.6125 69.1992 15.0616 69.6051 15.7351 69.6051H13.939C13.2654 69.6051 12.8164 70.0109 12.8164 70.6196V74.0692C12.8164 74.6779 13.2654 75.0838 13.939 75.0838H79.0487C79.7222 75.0838 80.1713 74.6779 80.1713 74.0692V70.6196C80.1713 70.0109 79.7222 69.6051 79.0487 69.6051Z" fill="#00CFC2"/>
                                <path d="M53.9141 17.4552H50.5464C50.3218 17.4552 50.0973 17.2523 50.0973 17.0494V13.8027C50.0973 13.5998 49.8728 13.3969 49.6483 13.3969H43.8109C43.5864 13.3969 43.3618 13.5998 43.3618 13.8027V16.8465C43.3618 17.0494 43.1373 17.2523 42.9128 17.2523H39.5451C39.3206 17.2523 39.096 17.4552 39.096 17.6581V22.9339C39.096 23.1369 39.3206 23.3398 39.5451 23.3398H42.9128C43.1373 23.3398 43.3618 23.5427 43.3618 23.7456V26.7893C43.3618 26.9923 43.5864 27.1952 43.8109 27.1952H49.6483C49.8728 27.1952 50.0973 26.9923 50.0973 26.7893V23.7456C50.0973 23.5427 50.3218 23.3398 50.5464 23.3398H53.9141C54.1386 23.3398 54.3631 23.1369 54.3631 22.9339V17.8611C54.3631 17.6581 54.1386 17.4552 53.9141 17.4552Z" fill="#00CFC2"/>
                                <path d="M53.9097 16.4398H50.991V13.8019C50.991 12.9903 50.3175 12.3815 49.4194 12.3815H43.582C42.6839 12.3815 42.0104 12.9903 42.0104 13.8019V16.4398H39.0917C38.4181 16.4398 37.5201 17.0486 37.5201 17.8602V23.136C37.5201 23.9477 38.1936 24.5565 39.0917 24.5565H42.0104V27.1944C42.0104 28.006 42.6839 28.6148 43.582 28.6148H49.4194C50.3175 28.6148 50.991 28.006 50.991 27.1944V24.5565H53.9097C54.8078 24.5565 55.4814 23.9477 55.4814 23.136V17.8602C55.4814 17.0486 54.5833 16.4398 53.9097 16.4398ZM42.6839 22.5273H39.7652V18.2661H42.6839C43.582 18.2661 44.2555 17.6573 44.2555 16.8457V14.2078H48.7459V16.8457C48.7459 17.6573 49.4194 18.2661 50.3175 18.2661H53.2362V22.5273H50.3175C49.4194 22.5273 48.7459 23.136 48.7459 23.9477V26.5856H44.2555V23.9477C44.2555 23.136 43.3575 22.5273 42.6839 22.5273Z" fill="#00756C"/>
                                <path d="M79.9521 68.5894H79.5031V64.1253C79.5031 63.5165 78.8295 62.9078 78.156 62.9078H76.5844V23.5422H78.156C78.8295 23.5422 79.5031 22.9334 79.5031 22.3247V17.6576C79.5031 17.0489 78.8295 16.4401 78.156 16.4401H60.8682C59.0721 10.5556 53.2347 6.7002 46.4992 6.7002C39.7637 6.7002 33.9263 10.7585 32.1302 16.4401H14.8424C14.1689 16.4401 13.4953 17.0489 13.4953 17.6576V22.3247C13.4953 22.9334 14.1689 23.5422 14.8424 23.5422H16.4141V63.1107H14.8424C14.1689 63.1107 13.4953 63.7194 13.4953 64.3282V68.7923H13.0463C12.3728 68.7923 11.6992 69.4011 11.6992 70.0098V75.0827C11.6992 75.6914 12.3728 76.3002 13.0463 76.3002H79.9521C80.6257 76.3002 81.2992 75.6914 81.2992 75.0827V69.8069C81.2992 69.1982 80.6257 68.5894 79.9521 68.5894ZM61.3173 18.2664H77.2579V21.513H61.3173C61.3173 21.1072 61.3173 20.7014 61.3173 20.2955C61.5418 19.6868 61.5418 18.8751 61.3173 18.2664ZM46.4992 31.4559C39.7637 31.4559 34.1508 26.383 34.1508 20.2955C34.1508 14.2081 39.7637 8.93227 46.4992 8.93227C53.2347 8.93227 58.8476 14.0052 58.8476 20.0926C58.8476 26.1801 53.2347 31.4559 46.4992 31.4559ZM59.7457 35.92H33.2528C31.2321 35.92 29.6605 37.3404 29.6605 39.1667V63.1107H18.6592V23.5422H31.9057C33.4773 29.6296 39.5392 33.8909 46.4992 33.8909C53.4592 33.8909 59.5212 29.6296 61.0928 23.5422H74.3392V63.1107H63.3379V39.1667C63.3379 37.3404 61.7663 35.92 59.7457 35.92ZM31.9057 39.1667C31.9057 38.5579 32.5792 37.9492 33.2528 37.9492H45.6012V63.1107H31.9057V39.1667ZM47.6218 37.9492H59.9702C60.6437 37.9492 61.3173 38.5579 61.3173 39.1667V63.1107H47.6218V37.9492ZM31.4566 21.513H15.7405V18.2664H31.6812C31.6812 18.8751 31.4566 19.4839 31.4566 20.0926C31.4566 20.7014 31.4566 21.1072 31.4566 21.513ZM15.7405 68.5894V64.9369H77.4824V68.5894H15.7405ZM79.0541 70.6186V74.271H13.9444V70.6186H79.0541Z" fill="#00756C"/>
                                <path d="M42.233 48.704C41.3349 48.704 40.6613 49.3128 40.6613 50.1244V52.5594C40.6613 53.3711 41.3349 53.9798 42.233 53.9798C43.131 53.9798 43.8046 53.3711 43.8046 52.5594V50.1244C43.8046 49.3128 43.131 48.704 42.233 48.704Z" fill="#00756C"/>
                                <path d="M50.7662 48.704C49.8681 48.704 49.1946 49.3127 49.1946 50.1244V52.5594C49.1946 53.371 49.8681 53.9798 50.7662 53.9798C51.6643 53.9798 52.3378 53.371 52.3378 52.5594V50.1244C52.3378 49.3127 51.6643 48.704 50.7662 48.704Z" fill="#00756C"/>
                            </g>
                        </svg>
                    </div>
                    <div className="col-12">
                        <div className="vsbox-title mb-1">Você está usando o Prontuário gratuito</div>
                        <div>O que te dá direito a degustar algumas funcionalidades do PRO limitadas.</div>
                    </div>
                    <div className="col-12">
                        <a 
                            style={{ 
                                cursor: "pointer",
                                fontSize: "14px",
                                fontWeight: 600
                            }} 
                            onClick={() => {
                                history.push("/limites")
                                saveOnTracker("Clicou", "Banner", "Limites do Prontuário gratuito", "Saiba o quanto você já usou")
                            }}
                        >
                            Saiba o quanto você já usou
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
  
export default HomeLimit;