import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import Collapse from '@mui/material/Collapse';
import FirstSteps from '../firstSteps/FirstSteps';

import { addEvent, saveOnTracker, useWindowSize, LocalStoragePrefix } from "../../utils"
import * as actions from "../../store/actions"
import { parseState } from '../../store';

import { ReactComponent as Logo } from "../../assets/vetsmart.svg"
import { ReactComponent as LogoMin } from "../../assets/vetsmart-logo.svg"
import { ReactComponent as Clinic } from "../../assets/clinic.svg"

import { ReactComponent as IconClose } from "../../assets/icon-close.svg"
import { ReactComponent as IconCalendarEdit } from "../../assets/icon-calendar-edit.svg"
import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as IconAudit } from "../../assets/icon-audit.svg"
import { ReactComponent as IconCalendarCheck } from "../../assets/icon-calendar-check.svg"
import { ReactComponent as IconDashboard } from "../../assets/icon-dashboard.svg"
import { ReactComponent as IconFinancial } from "../../assets/icon-financial.svg"
import { ReactComponent as IconConfig } from "../../assets/icon-config.svg"
import { ReactComponent as IconChangeClinic } from "../../assets/icon-change-clinic.svg"

import { ReactComponent as IconHome } from "../../assets/icon-home.svg"
import { ReactComponent as IconHealth } from "../../assets/icon-health.svg"
import { ReactComponent as IconExternalLink } from "../../assets/icon-external-link.svg"
import { ReactComponent as IconHistory } from "../../assets/icon-history-menu.svg"
import { ReactComponent as IconPaw } from "../../assets/icon-paw-menu.svg"
import { ReactComponent as IconPrint } from "../../assets/icon-print.svg"
import { ReactComponent as IconGraphSquare } from "../../assets/icon-graph-square.svg"
import { ReactComponent as IconPrimaryInviteVet } from "../../assets/icon-primary-invite-vet.svg"
import { ReactComponent as IconQuestionO } from "../../assets/icon-question-o.svg"
import { ReactComponent as IconUser } from "../../assets/icon-user-menu.svg"
import { ReactComponent as IconVaccine } from "../../assets/icon-vaccine.svg"
import { ReactComponent as IconProtocol } from "../../assets/icon-menu-protocol.svg"
import { ReactComponent as IconChemical } from "../../assets/icon-chemical.svg"
import { ReactComponent as IconSupplyHistory } from "../../assets/icon-supply-history.svg"
import { ReactComponent as IconClinicConfig } from "../../assets/icon-clinic-config.svg"

import { NavLink, useLocation } from 'react-router-dom'
import { CircularProgress, List, ListItem } from '@mui/material';
import { ACL } from '../../constants';

import StoreInfo from '../../pages/clinic/StoreInfo';

import './Menu.scss'
const useStyles = makeStyles((theme) => ({
    drawer: {
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    }
}))

var timeoutToSubmenu = null
const Menu = (props) => {

    const {
        pro,
        user,
        permissions,
        clinic,
        history,
        mobileMenuOpen,
        onMenuChange,
        location: { pathname }
    } = props

    const classes = useStyles();
    const location = useLocation()
    const {
        width
    } = useWindowSize()

    const canOpenMenu = width >= 960
    const isMenuMobile = width <= 768

    const menuContent = useRef(null)
    
    const [ open, setOpen ] = useState(true)
    const [ financialCollapse, setFinancialCollapse ] = useState(location.pathname.startsWith('/financeiro'))
    const [ supplyCollapse, setSupplyCollapse ] = useState(location.pathname.endsWith('/insumo'))
    const [ showClinic, setShowClinic ] = useState(false)
    const [ showHelp, setShowHelp ] = useState(false)
    const [ isShowSteps, setIsShowSteps ] = useState(false)
    
    const changeClinic = () => {
        localStorage.removeItem(`${LocalStoragePrefix()}selectedClinic`);
        props.dispatch(actions.getSelectedClinicUser(null))
        history.push("/selecionar-clinica", {
            fromUrl: location.search,
            disableAuto: true
        })
    }

    useEffect(() => {
        if(!pathname.startsWith('/financeiro')){
            setFinancialCollapse(false)
        }
        if(!pathname.endsWith('/insumo')){
            setSupplyCollapse(false)
        }
    }, [ pathname ])

    useEffect(() => {
        if (!canOpenMenu && open && !isMenuMobile) {
            setOpen(false)
        }
    }, [ width ])

    useEffect(() => {
        if(isMenuMobile && mobileMenuOpen && !isShowSteps){
            onMenuChange();
        }
    }, [ location ]);

    useEffect(() => {
        if(isMenuMobile){
            setOpen(true)
        }
    }, [ isMenuMobile ])

    useEffect(() => {
        const positionSubMenu = () => {
            const element = document.querySelectorAll(".menu-subitem.bottom-subitem.selected")[0]
            if (element) {
                const bounds = element.getBoundingClientRect()
                const submenu = element.nextSibling
                if (submenu) {
                    submenu.style.transform = `translateY(-${window.innerHeight - bounds.bottom}px)`
                }
            }
        }
        if (showHelp) {
            window.addEventListener("resize", positionSubMenu)
            positionSubMenu()
        } else {
            window.removeEventListener("resize", positionSubMenu)
        }
    }, [ showHelp ])
    
    useEffect(() => {
        if (!menuContent || !menuContent.current)
            return

        let preventDuplicate
        const resizeObserver = new ResizeObserver((entries) => {
            if (preventDuplicate) {
                clearTimeout(preventDuplicate)
            }
            preventDuplicate = setTimeout(() => {
                if (!menuContent || !menuContent.current)
                    return
                    
                const menu = menuContent.current.parentElement
                if (menu.scrollHeight > menu.offsetHeight) {
                    menu.classList.add("menu-shadow")
                    menu.scrollTop = 0
                } else {
                    menu.classList.remove("menu-shadow")
                }
            }, 300)
        });
        resizeObserver.observe(menuContent.current);
        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        if (!menuContent || !menuContent.current)
            return

        if(isMenuMobile){
            const menu = menuContent.current.parentElement
            menu.scrollTop = 0
        }
    }, [ showClinic, showHelp, isShowSteps ])

    if(!user || !user.object){
        return <div></div>
    }
    
    return (
        <Drawer
            id="menu"
            variant={!isMenuMobile ? "permanent" : undefined}
            open={isMenuMobile ? mobileMenuOpen : undefined}
            onClose={() => isMenuMobile && onMenuChange()}
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                root: `px-0 ${open ? "isOpened" : "isClosed"}`,
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: isMenuMobile || !open
                }),
            }}
        >
            <div className={`col p-0 menu menu-sidebar ${open ? 'open' : 'closed'}`} >
                <div className="menu-header row align-items-center m-0" onClick={() => {
                    if(isMenuMobile){
                        setShowClinic(false);
                        setShowHelp(false);
                    }
                }}>
                    <div className="col ps-0 pe-1">
                        {isMenuMobile && (showClinic || showHelp) ? (
                            <div className="menu-mobile-back">Voltar para Categorias</div>
                        ) : (open ? (
                            <Logo />
                        ) : (
                            <LogoMin />
                        ))}
                    </div>
                    { canOpenMenu && (
                        <div className="sidebar-toggle" onClick={() => setOpen(!open)}>
                            <div className="icon-circle">
                                <IconArrowLeft width="8" height="12" fill="#FFFFFF" />
                            </div>
                        </div>
                    )}
                    {isMenuMobile && (
                        <div className="sidebar-close" onClick={onMenuChange}>
                            <div className="icon-circle">
                                <IconClose fill="var(--vsprontuario-primary-color)" width="20" height="20" />
                            </div>
                        </div>
                    )}
                </div>
                <div className={`menu-content ${(isMenuMobile && (showClinic || showHelp || isShowSteps)) ? 'overflow-hidden' : ''}`}>
                    <div
                        className={`menu-item ${pro?.access ? 'bg-blue' : 'bg-green'} ${showClinic ? "active" : ""}`}
                        onClick={(e) => {
                            // if(!(permissions?.check(ACL.ADMIN, ACL.Rule.VIEW) || clinic?.isOwner)){
                            //     return
                            // }
                            
                            let main = e.target
                            if (!showClinic) {
                                setShowClinic(true)
                                addEvent("FirstSteps__close", {})
                                const onClickOutside = (e) => {
                                    if (!main.contains(e.target)) {
                                        setShowClinic(false)
                                        removeEventListener("click", onClickOutside)
                                    }
                                }
                                addEventListener("click", onClickOutside)
                            } else {
                                setShowClinic(false)
                            }
                        }}
                    >
                        <div className="row align-items-center h-100 clinic">
                            <div className="col-auto clinic-icon">
                                <Clinic 
                                    fill={pro?.access ? "var(--vsprontuario-secondary-color)" : "var(--vsprontuario-primary-color)"} 
                                    width="32px" 
                                    height="36px" 
                                />
                            </div>
                            <div className="col ps-0 menu-text clinic-name text-truncate">
                                Clínica { pro?.access && <span className="badge rounded-pill bg-secondary">PRO</span> }
                                <h3 className="text-truncate mb-0" style={{ marginRight: "-16px" }}>
                                    {(clinic && clinic.name) || "procurando..."}
                                </h3>
                            </div>
                            <div className="col-auto">
                                <IconConfig 
                                    fill={pro?.access ? "var(--vsprontuario-secondary-color)" : "var(--vsprontuario-primary-color)"} 
                                    width="24px" 
                                    height="24px" 
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        id="clinic-view"
                        className={`change-clinic menu-item ${pro?.access ? 'pro' : ''}`} onClick={changeClinic}
                    >
                        <div className="row w-100 gx-0">
                            <div className="col menu-text">Trocar de Clínica</div>
                            <IconChangeClinic 
                                className="col-auto menu-icon pe-0" 
                                fill={pro?.access ? "var(--vsprontuario-secondary-color)" : "var(--vsprontuario-primary-color)"}
                                width="24px" 
                                height="24px" 
                            />
                        </div>
                    </div>
                    <FirstSteps onChangeShowSteps={showSteps => setIsShowSteps(showSteps)} />
                    <div ref={menuContent}>
                        <NavLink className={`menu-item bg-gray ${location.pathname == "/" ? "selected" : ""}`} to="/">
                            <div className="row">
                                <div className="col-auto menu-icon">
                                    <IconHome width="24" height="24" fill="var(--vsprontuario-gray-color-light)" />
                                </div>
                                <div className="col ps-0 menu-text">
                                    Início
                                </div>
                            </div>
                        </NavLink>
                        { permissions?.check(ACL.OWNER, ACL.Rule.VIEW) && (
                            <NavLink
                                id="menu-owner-link"
                                className={`menu-item bg-gray ${location.pathname.match(/^\/tutor/g) ? "selected" : ""}`}
                                to="/tutor"
                            >
                                <div className="row">
                                    <div className="col-auto menu-icon">
                                        <IconUser width="24" height="24" fill="var(--vsprontuario-gray-color-light)" />
                                    </div>
                                    <div className="col ps-0 menu-text">
                                        Tutores
                                    </div>
                                </div>
                            </NavLink>
                        )}
                        { permissions?.check(ACL.PATIENT, ACL.Rule.VIEW) && (
                            <NavLink
                                id="menu-patient-link"
                                className={`menu-item bg-gray ${location.pathname.match(/^\/animal/g) ? "selected" : ""}`}
                                to="/animal"
                            >
                                <div className="row">
                                    <div className="col-auto menu-icon">
                                        <IconPaw width="24" height="24" fill="var(--vsprontuario-gray-color-light)" />
                                    </div>
                                    <div className="col ps-0 menu-text">
                                        Animais
                                    </div>
                                </div>
                            </NavLink>
                        )}
                        { permissions?.check(ACL.HISTORY, ACL.Rule.VIEW) && (
                            <NavLink className={`menu-item bg-gray ${location.pathname == "/historico" ? "selected" : ""}`} to="/historico">
                                <div className="row">
                                    <div className="col-auto menu-icon">
                                        <IconHistory width="24" height="24" fill="var(--vsprontuario-gray-color-light)" />
                                    </div>
                                    <div className="col ps-0 menu-text">
                                        Histórico
                                    </div>
                                </div>
                            </NavLink>
                        )}
                        { permissions?.check(ACL.RETURN, ACL.Rule.VIEW) && (
                            <NavLink className={`menu-item bg-gray ${location.pathname.startsWith("/retorno") ? "selected" : ""}`} to="/retorno">
                                <div className="row">
                                    <div className="col-auto menu-icon">
                                        <IconCalendarEdit width="24" height="24" fill="var(--vsprontuario-gray-color-light)" />
                                    </div>
                                    <div className="col ps-0 menu-text">
                                        Retornos
                                    </div>
                                </div>
                            </NavLink>
                        )}
                        { permissions?.check(ACL.VACCINE, ACL.Rule.VIEW) && (
                            <>
                                <NavLink className={`menu-item bg-gray ${location.pathname == "/vacina" ? "selected" : ""}`} to="/vacina">
                                    <div className="row">
                                        <div className="col-auto menu-icon">
                                            <IconVaccine width="24" height="24" fill="var(--vsprontuario-gray-color-light)" />
                                        </div>
                                        <div className="col ps-0 menu-text">
                                            Vacinas
                                        </div>
                                    </div>
                                </NavLink>
                                <NavLink className={`menu-item bg-gray ${location.pathname == "/protocolo/vacina" ? "selected" : ""}`} to="/protocolo/vacina">
                                    <div className="row">
                                        <div className="col-auto menu-icon">
                                            <IconProtocol width="24" height="24" fill="var(--vsprontuario-gray-color-light)" />
                                        </div>
                                        <div className="col ps-0 menu-text text-wrap">
                                            Protocolos de Vacinas
                                        </div>
                                        {!pro?.access && 
                                        <div className="col-auto pro-label ps-0 pe-4">
                                            <span className="badge rounded-pill bg-secondary">
                                                PRO
                                            </span>
                                        </div>}
                                    </div>
                                </NavLink>
                            </>
                        )}
                        { permissions?.check(ACL.CALENDAR, ACL.Rule.VIEW) && (
                            <NavLink className={`menu-item bg-gray ${location.pathname == "/agenda" ? "selected" : ""}`} to="/agenda">
                                <div className="row">
                                    <div className="col-auto menu-icon">
                                        <IconCalendarCheck width="24" height="24" fill="var(--vsprontuario-gray-color-light)" />
                                    </div>
                                    <div className="col px-0 menu-text">
                                        Agenda 
                                    </div>
                                    {!pro?.access && 
                                    <div className="col-auto pro-label ps-0 pe-4">
                                        <span className="badge rounded-pill bg-secondary">
                                            PRO
                                        </span>
                                    </div>}
                                </div>
                            </NavLink>
                        )}
                        { permissions?.check(ACL.SERVICE, ACL.Rule.VIEW) && (
                            <NavLink className={`menu-item bg-gray ${location.pathname == "/servico" ? "selected" : ""}`} to="/servico">
                                <div className="row">
                                    <div className="col-auto menu-icon">
                                        <IconHealth width="24" height="24" fill="var(--vsprontuario-gray-color-light)" />
                                    </div>
                                    <div className="col px-0 menu-text">
                                        Serviços 
                                    </div>
                                    {!pro?.access && 
                                    <div className="col-auto pro-label ps-0 pe-4">
                                        <span className="badge rounded-pill bg-secondary">
                                            PRO
                                        </span>
                                    </div>}
                                </div>
                            </NavLink>
                        )}
                        { (permissions?.check(ACL.SUPPLY, ACL.Rule.VIEW) || 
                            permissions?.check(ACL.SUPPLY_TRANSACTION, ACL.Rule.VIEW)) && (
                            <>
                                <div 
                                    className={`menu-item menu-subitem bg-gray ${supplyCollapse ? "selected" : ""}`}
                                    onClick={() => setSupplyCollapse(!supplyCollapse)}
                                >
                                    <div className="row">
                                        <div className="col-auto menu-icon">
                                            <IconChemical width="24" height="24" fill="var(--vsprontuario-gray-color-light)" />
                                        </div>
                                        <div className="col px-0 menu-text">
                                            Insumos
                                        </div>
                                        {!pro?.access && 
                                        <div className="col-auto pro-label ps-0 pe-4">
                                            <span className="badge rounded-pill bg-secondary">
                                                PRO
                                            </span>
                                        </div>}
                                    </div>
                                </div>
                                <Collapse component="div" in={supplyCollapse}>
                                    <List disablePadding>
                                        { permissions?.check(ACL.SUPPLY, ACL.Rule.VIEW) && (
                                            <ListItem 
                                                button 
                                                component={NavLink} 
                                                className={`menu-item bg-gray ${location.pathname == "/insumo" ? "selected" : ""}`} 
                                                to="/insumo">

                                                <div className="row">
                                                    <div className={`col ${open ? "ps-4" : "px-2"} menu-text`}>
                                                        Cadastro de Insumos
                                                    </div>
                                                </div>
                                            </ListItem>
                                        )}
                                        { permissions?.check(ACL.SUPPLY_TRANSACTION, ACL.Rule.VIEW) && (
                                            <ListItem 
                                                button 
                                                component={NavLink} 
                                                className={`menu-item bg-gray ${location.pathname == "/movimentacoes/insumo" ? "selected" : ""}`} 
                                                to="/movimentacoes/insumo">

                                                <div className="row">
                                                    <div className={`col ${open ? "ps-4" : "px-2"} menu-text`}>
                                                        Histórico de Insumos
                                                    </div>
                                                </div>
                                            </ListItem>
                                        )}
                                    </List>
                                </Collapse>
                            </>
                        )}
                        { permissions?.check(ACL.FINANCIAL, ACL.Rule.VIEW) && (
                            <>
                                <div
                                    className={`menu-item menu-subitem bg-gray ${financialCollapse ? "selected" : ""}`}
                                    onClick={() => setFinancialCollapse(!financialCollapse)}
                                >
                                    <div className="row">
                                        <div className="col-auto menu-icon">
                                            <IconFinancial width="24" height="24" fill="var(--vsprontuario-gray-color-light)" />
                                        </div>
                                        <div className="col px-0 menu-text">
                                            Financeiro
                                        </div>
                                        {!pro?.access && 
                                        <div className="col-auto pro-label ps-0 pe-4">
                                            <span className="badge rounded-pill bg-secondary">
                                                PRO
                                            </span>
                                        </div>}
                                    </div>
                                </div>
                                <Collapse component="div" in={financialCollapse}>
                                    <List disablePadding>
                                        { permissions?.check(ACL.INFLOW, ACL.Rule.VIEW) && (
                                            <ListItem 
                                                button 
                                                component={NavLink} 
                                                className={`menu-item bg-gray ${location.pathname == "/financeiro/entrada" ? "selected" : ""}`} 
                                                to="/financeiro/entrada">

                                                <div className="row">
                                                    <div className={`col ${open ? "ps-4" : "px-2"} menu-text`}>
                                                        Entrada de Caixa
                                                    </div>
                                                </div>
                                            </ListItem>
                                        )}
                                        { permissions?.check(ACL.OUTFLOW, ACL.Rule.VIEW) && (
                                            <ListItem 
                                                button 
                                                component={NavLink} 
                                                className={`menu-item bg-gray ${location.pathname == "/financeiro/saida" ? "selected" : ""}`} 
                                                to="/financeiro/saida">

                                                <div className="row">
                                                    <div className={`col ${open ? "ps-4" : "px-2"} menu-text`}>
                                                        Saída de Caixa
                                                    </div>
                                                </div>
                                            </ListItem>
                                        )}
                                        { permissions?.check(ACL.INFLOW, ACL.Rule.VIEW) && (
                                            <ListItem 
                                                button 
                                                component={NavLink} 
                                                className={`menu-item bg-gray ${location.pathname == "/financeiro/receber" ? "selected" : ""}`} 
                                                to="/financeiro/receber">

                                                <div className="row">
                                                    <div className={`col ${open ? "ps-4" : "px-2"} menu-text`}>
                                                        Contas a Receber
                                                    </div>
                                                </div>
                                            </ListItem>
                                        )}
                                        { permissions?.check(ACL.OUTFLOW, ACL.Rule.VIEW) && (
                                            <ListItem 
                                                button 
                                                component={NavLink} 
                                                className={`menu-item bg-gray ${location.pathname == "/financeiro/pagar" ? "selected" : ""}`} 
                                                to="/financeiro/pagar">

                                                <div className="row">
                                                    <div className={`col ${open ? "ps-4" : "px-2"} menu-text`}>
                                                        Contas a Pagar
                                                    </div>
                                                </div>
                                            </ListItem>
                                        )}
                                    </List>
                                </Collapse>
                            </>
                        )}
                        { permissions?.check(ACL.CONTROL_PANEL, ACL.Rule.VIEW) && (
                            <NavLink className={`menu-item bg-gray ${location.pathname == "/painel" ? "selected" : ""}`} to="/painel">
                                <div className="row">
                                    <div className="col-auto menu-icon">
                                        <IconDashboard width="24" height="24" fill="var(--vsprontuario-gray-color-light)" />
                                    </div>
                                    <div className="col px-0 menu-text text-wrap">
                                        Painel de Controle
                                    </div>
                                    {!pro?.access && 
                                    <div className="col-auto pro-label ps-0 pe-4">
                                        <span className="badge rounded-pill bg-secondary">
                                            PRO
                                        </span>
                                    </div>}
                                </div>
                            </NavLink>
                        )}
                        { clinic && clinic.name && (
                            <div
                                className={`submenu submenu-options ${showClinic ? "submenu-active" : ""}`}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    let main = e.target
                                    const onClickOutside = (e) => {
                                        if (!main.contains(e.target)) {
                                            if(isMenuMobile){
                                                setShowClinic(true)
                                            }
                                            removeEventListener("click", onClickOutside)
                                        }
                                    }
                                    addEventListener("click", onClickOutside)
                                }}
                            >
                                <div className={`submenu-item`} onClick={() => {
                                    history.push('/configuracao-clinica')
                                    saveOnTracker("Clicou", "Menu Prontuário", "Configurações da Clínica")
                                    setShowClinic(false)
                                }}>
                                    <IconClinicConfig width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                    Configurações da Clínica
                                </div>
                                {(permissions?.check(ACL.ADMIN, ACL.Rule.VIEW) || clinic?.isOwner) && !pro?.access && (
                                    <div className={`submenu-item`} onClick={() => {
                                        setShowClinic(false)
                                        saveOnTracker("Clicou", "Menu Prontuário", "Limites Prontuário Gratuito")
                                        history.push("/limites")
                                    }}>
                                        <IconGraphSquare width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                        Limites Prontuário Gratuito
                                    </div>
                                )}
                                { clinic.isOwner && (
                                    <div className={`submenu-item`} onClick={() => {
                                        setShowClinic(false)
                                        saveOnTracker("Clicou", "Menu Prontuário", "Convidar veterinário")
                                        history.push("/configuracao-clinica/gerenciar-acessos/novo")
                                    }}>
                                        <IconPrimaryInviteVet width="24" height="24" />
                                        Convidar veterinário
                                    </div>
                                )}
                                { permissions?.check(ACL.ADMIN) && (
                                    <div className="submenu-item" onClick={() => {
                                        history.push('/auditoria')
                                        saveOnTracker("Clicou", "Menu Prontuário", "Histórico de Edições")
                                        setShowClinic(false)
                                    }}>
                                        <IconAudit width="24" height="24" />
                                        Histórico de Edições
                                    </div>
                                )}
                                <div className={`submenu-item`} onClick={() => {
                                    setShowClinic(false)
                                    history.push("/impressos-veterinarios")
                                }}>
                                    <IconPrint width="24" height="24" />
                                    Impressos Veterinários
                                </div>
                            </div>
                        )}
                        <div
                            className={`menu-item menu-subitem bottom-subitem bg-gray ${showHelp ? "selected" : ""}`}
                            onClick={() => {
                                setTimeout(() => {
                                    setShowHelp(true)
                                });
                            }}
                            onMouseEnter={() => {
                                if (!isMenuMobile) {
                                    setTimeout(() => {
                                        setShowHelp(true)
                                    })
                                }
                            }}
                            onMouseLeave={() => {
                                if (!isMenuMobile) {
                                    timeoutToSubmenu = setTimeout(() => {
                                        setShowHelp(false)
                                    }, 1500)
                                }
                            }}
                        >
                            <div className="row">
                                <div className="col-auto menu-icon">
                                    <IconQuestionO width="24" height="24" fill="var(--vsprontuario-gray-color-light)" />
                                </div>
                                <div className="col ps-0 menu-text">
                                    Ajuda
                                </div>
                            </div>
                        </div>
                        { showHelp && (
                            <div
                                className="submenu submenu-active submenu-help"
                                onMouseEnter={() => {
                                    clearTimeout(timeoutToSubmenu)
                                }}
                                onMouseLeave={() => {
                                    if (!isMenuMobile)
                                        setShowHelp(false)
                                }}
                            >
                                <a
                                    href="https://pl-vetsmart.zendesk.com/hc/pt-br/categories/4410355279771-Prontu%C3%A1rio"
                                    className="submenu-item divider"
                                    target="_blank"
                                    onClick={() => {
                                        setShowHelp(false)
                                    }}
                                >
                                    <span className="pe-2">Central de Ajuda</span>
                                </a>
                                <a className="submenu-item divider" href={`${process.env.REACT_APP_ACCOUNT}/termos`} target="_blank" onClick={() => setShowHelp(false)}>
                                    <span className="pe-2">Termos de Uso</span>
                                    <IconExternalLink width="24" height="24" />
                                </a>
                                <a className="submenu-item" href={`${process.env.REACT_APP_ACCOUNT}/privacidade`} target="_blank" onClick={() => setShowHelp(false)}>
                                    <span className="pe-2">Políticas de Privacidade</span>
                                    <IconExternalLink width="24" height="24" />
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Drawer>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions,
        pro: state.featuresPro,
    };
};
  
export default connect(mapStateToProps)(Menu);