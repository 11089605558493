import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { ReactComponent as IconClose } from "../../assets/icon-close.svg"
import { ReactComponent as IconVirtualAssistantSelect } from "../../assets/virtual-assistant-select-2.svg"
import { ReactComponent as IconVirtualAssistantAnamnesis } from "../../assets/icon-virtual-assistant-anamnesis.svg"
import { ReactComponent as IconVirtualAssistantDiagnosis } from "../../assets/icon-virtual-assistant-diagnosis.svg"
import { ReactComponent as IconVirtualAssistantHistoric } from "../../assets/icon-virtual-assistant-historic.svg"
import { ReactComponent as IconHeaderIA } from "../../assets/icon-header-ia.svg"

import "./VirtualAssistantModal.scss"
import { APP_ID, APP_NAME, APP_DEVICE, APP_VERSION } from '../../constants';
import { LocalStoragePrefix, saveOnTracker } from '../../utils';

export const LocalStorageVirtualAssistantModalKey = `${LocalStoragePrefix()}showVirtualAssistantModal`

const VirtualAssistantModal = (props) => {
    const {
        history
    } = props

    const [ showModal, setShowModal ] = useState(false)

    useEffect(() => {
        setShowModal(false)
        const hasShowModal = localStorage.getItem(LocalStorageVirtualAssistantModalKey)
        if(!hasShowModal){
            localStorage.setItem(LocalStorageVirtualAssistantModalKey, true)
        }
        setShowModal(hasShowModal === 'false' ? false : true)
        if(hasShowModal !== 'false'){
            saveOnTracker("Visualizou", "Assistente (Modal)")
        }
    }, [])

    const hideModal = () => {
        localStorage.setItem(LocalStorageVirtualAssistantModalKey, false)
        setShowModal(false)
    }

    if(!showModal){
        return <></>
    }

    return (
        <div id="virtual-assistant-modal">
            <div className="virtual-assistant-modal-popup">
                <div className="row flex-column flex-md-row gx-0">
                    <div className="col-auto flex-shrink-0 px-3 py-4 p-md-5 virtual-assistant-modal-select-part">
                        <div className="text-md-center mx-auto my-4">
                            <IconVirtualAssistantSelect />
                        </div>
                        <div className="row">
                            <div className="col p-0">
                                <button className="btn-link text-center cursor-pointer d-block" onClick={() => {
                                    history.push(`/assistente/resumo-historico-clinico/animal/selecionar`)
                                    hideModal()
                                    saveOnTracker("Clicou", "Assistente (Modal)", "Resumo do Histórico Clínico")
                                }}>
                                    <IconVirtualAssistantHistoric className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                    Resumo do histórico clínico
                                </button>
                                <button className="btn-link text-center cursor-pointer d-block" onClick={() => {
                                    return
                                    history.push(`/assistente/diagnostico-diferencial`)
                                    hideModal()
                                }}>
                                    <IconVirtualAssistantDiagnosis className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                    Diagnóstico diferencial<span className="soon-tag">Breve</span>
                                </button>
                                <button className="btn-link text-center cursor-pointer d-block" onClick={() => {
                                    return
                                    history.push(`/assistente/ditar-anamnese`)
                                    hideModal()
                                }}>
                                    <IconVirtualAssistantAnamnesis className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                    Ditar anamnese<span className="soon-tag">Breve</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col flex-grow-1 virtual-assistant-explanation-part">
                        <button
                            className="virtual-assistant-modal-close"
                            onClick={() => {
                                hideModal()
                                saveOnTracker("Clicou", "Assistente (Modal)", "Fechar")
                            }}
                        >
                            <IconClose fill="var(--vsprontuario-primary-color)" width="20" height="20" />
                            <span>FECHAR</span>
                        </button>
                        <h2>
                            <IconHeaderIA className="me-2" width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />
                            Assistente Vetsmart <span className="va-beta-text">Beta</span>
                        </h2>
                        
                        <p>Você é nosso convidado para testar a nossa mais nova ferramenta, o <b>Assistente Vetsmart Beta!</b></p>
                        <p>Agora você conta com um assistente digital que pode te ajudar na elaboração de resumos do histórico clínico dos animais em tratamento.</p>
                        <p>Essa nova funcionalidade está disponível para você até o dia <b>30/09</b>, contamos com a sua ajuda para nos enviar suas impressões e sugestões de melhoria. Aproveite!</p>
                        <p>Muito em breve você terá acesso a novas funcionalidades do Assistente, fique ligado!</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VirtualAssistantModal